function str_pad(input, pad_length, pad_string, pad_type) {
    //  discuss at: http://phpjs.org/functions/str_pad/
    // original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // improved by: Michael White (http://getsprink.com)
    //    input by: Marco van Oort
    // bugfixed by: Brett Zamir (http://brett-zamir.me)
    //   example 1: str_pad('Kevin van Zonneveld', 30, '-=', 'STR_PAD_LEFT');
    //   returns 1: '-=-=-=-=-=-Kevin van Zonneveld'
    //   example 2: str_pad('Kevin van Zonneveld', 30, '-', 'STR_PAD_BOTH');
    //   returns 2: '------Kevin van Zonneveld-----'

    var half = '',
        pad_to_go;

    var str_pad_repeater = function(s, len) {
        var collect = '',
            i;

        while (collect.length < len) {
            collect += s;
        }
        collect = collect.substr(0, len);

        return collect;
    };

    input += '';
    pad_string = pad_string !== undefined ? pad_string : ' ';

    if (pad_type !== 'STR_PAD_LEFT' && pad_type !== 'STR_PAD_RIGHT' && pad_type !== 'STR_PAD_BOTH') {
        pad_type = 'STR_PAD_RIGHT';
    }
    if ((pad_to_go = pad_length - input.length) > 0) {
        if (pad_type === 'STR_PAD_LEFT') {
            input = str_pad_repeater(pad_string, pad_to_go) + input;
        } else if (pad_type === 'STR_PAD_RIGHT') {
            input = input + str_pad_repeater(pad_string, pad_to_go);
        } else if (pad_type === 'STR_PAD_BOTH') {
            half = str_pad_repeater(pad_string, Math.ceil(pad_to_go / 2));
            input = half + input + half;
            input = input.substr(0, pad_length);
        }
    }

    return input;
}

// Get parameter by name
function getParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/*
 * cleanUrl(string)
 * ----------------
 * Devuelve la cadena pasa por par�metro pero
 * en formato para utilizar en URLs amigables
 */
cleanUrl = function (cadena) {
    cadena = cadena.quitarAcentos(); // Requiere el m�todo "quitarAcentros"
    cadena = cadena.toLowerCase();
    cadena = cadena.replace(/[^a-z0-9]+/g, '-');
    return cadena;
};

// LIMPIAR ACENTOS DE CADENAS
String.prototype.quitarAcentos = function () {
    var __r = {
        '�': 'A', '�': 'A', '�': 'A', '�': 'A', '�': 'A', '�': 'A', '�': 'E',
        '�': 'E', '�': 'E', '�': 'E', '�': 'E',
        '�': 'I', '�': 'I', '�': 'I',
        '�': 'O', '�': 'O', '�': 'O', '�': 'O',
        '�': 'U', '�': 'U', '�': 'U', '�': 'U',
        '�': 'N'
    };
    return this.replace(/[�����������������������]/gi, function (m) {
        var ret = __r[m.toUpperCase()];
        if (m === m.toLowerCase()) ret = ret.toLowerCase();
        return ret;
    });
};