$(document).ready(function (e) {

    // Compartir inmueble
    if (typeof navigator.share == 'undefined') {
        $('#btn-compartir').remove();
    }
    $('#btn-compartir').click(function (e) {
        e.preventDefault();
        const files = $(this).data('files').split(',').map(element => element.trim());
        const shareData = {
            title: $(this).data('title'),
            text: $(this).data('text'),
            url: $(this).data('url'),
            files: files
        };
        try {
            navigator.share(shareData);
        } catch (error) {
            console.log(error);
        }

    });

    // Carrusel fichas
    $('.ficha__images').each(function (key, item) {
        $(item).slick({
            dots: false,
            lazyLoad: 'ondemand',
            prevArrow: $(item).closest('.ficha').find('.slider-prev'),
            nextArrow: $(item).closest('.ficha').find('.slider-next'),
        });
    });

    // Bucar
    $('.btn-search').click(function (e) {
        e.preventDefault();
        if (!$(this).closest('form').find('input').val() == '') {
            $(this).closest('form').submit();
        }

    });


    if ($('#modal-not-found').length) $('#modal-not-found').modal();

    // Desplegar area de contacto en inmuebles
    $("#btn-pedir-info,#btn-cancel-pedir-info").click(function (e) {
        $('#panel-mas-info').slideToggle();
        $('#panel-contacto').slideToggle();
    });

    // Validación de formularios
    txt_required = "Es necesario rellenar este campo";
    txt_email = "El email introducido no es válido";
    txt_check = "Debes aceptar la politica de privacidad";
    $("#frm_contacto").validate({
        focusCleanup: true,
        rules: {
            nombre: { required: true },
            telefono: { required: true },
            email: { required: true, email: true },
            privacidad: { required: true }
        },
        messages: {
            nombre: { required: txt_required },
            telefono: { required: txt_required },
            email: { required: txt_required, email: txt_email },
            privacidad: { required: txt_check }
        },
        submitHandler: function (form) {
            var v_form = $(form);
            if ($('[name="g-recaptcha-response"]').val() == "") {
                alert('Debes pasar la prueba de humanos, marca "No soy un robot"');
            } else {

                var div_response = v_form.find('.response');
                var div_content = v_form.find('.form-content');

                $.ajax({

                    method: v_form.attr('method'),
                    url: v_form.attr('action'),
                    data: v_form.serialize(),
                    success: function (data) {
                        var response = JSON.parse(data);
                        var r_html;
                        if (!response.success) {
                            r_html = '<div class="alert alert-danger">' + response.html + '</div>';
                        } else {
                            r_html = '<div class="alert alert-success"><i class="fa fa-check"></i> ' + response.html + '</div>';
                            form.reset();
                        }
                        div_content.slideUp();
                        div_response.hide().html(r_html).slideDown();

                        return false;
                    }
                });
                return false;
            }
        },
        invalidHandler: function (event, validator) {
            // 'this' refers to the form
            var errors = validator.numberOfInvalids();
            if (errors) {
                var message = errors == 1 ? 'Debes corregir el campo señalado' : 'Debes corregir los ' + errors + ' campos señalados';
            }
            alert(message);
        }

    });

    $("#frm_enviar").validate({
        focusCleanup: true,
        rules: {
            amigo: { required: true },
            email: { required: true, email: true },
            nombre: { required: true }
        },
        messages: {
            amigo: { required: txt_required },
            email: { required: txt_required, email: txt_email },
            nombre: { required: txt_required }
        },
        submitHandler: function (form) {
            var v_form = $(form);
            if (v_form.find('[name="g-recaptcha-response"]').val() == "") {
                alert('Debes pasar la prueba de humanos, marca "No soy un robot"');
            } else {

                var div_response = v_form.find('.response');
                var div_content = v_form.find('.form-content');

                $.ajax({
                    method: v_form.attr('method'),
                    url: v_form.attr('action'),
                    data: v_form.serialize(),

                    success: function (data) {
                        var response = JSON.parse(data);
                        var r_html;
                        if (!response.success) {
                            r_html = '<div class="alert alert-danger">' + response.html + '</div>';
                        } else {
                            r_html = '<div class="alert alert-success"><i class="fa fa-check"></i> ' + response.html + '</div>';
                            form.reset();
                        }
                        div_content.slideUp();
                        div_response.hide().html(r_html).slideDown();
                        return false;
                    }
                });
                return false;
            }
        },
        invalidHandler: function (event, validator) {
            // 'this' refers to the form
            var errors = validator.numberOfInvalids();
            if (errors) {
                var message = errors == 1 ? 'Debes corregir el campo señalado' : 'Debes corregir los ' + errors + ' campos señalados';
            }
            alert(message);
        }

    });

    // Popup Resources
    $(document).on('click', '.load-resource', function (e) {
        e.preventDefault();
        resource_url = $(this).attr('href');
        $.ajax({
            url: resource_url,
            type: 'GET',
            dataType: 'json'
        })
            .done(function (data) {
                $("#modal-resource-body").html(data.html);
                $("#modal-resource").modal();
            })
            .fail(function () {
                $("#modal-resource-body").html('<div class="text-center"><h4>Error al intentar cargar el contenido</h4></div>');
                $("#modal-resource").modal();
            });
    });

    $(document).on('click', '.load-iframe', function (e) {
        e.preventDefault();
        resource_url = $(this).attr('href');

        $("#modal-resource .modal-body").html('');
        $("#modal-resource .modal-body").append('<iframe src="' + resource_url + '" style="width: 100%; height: 500px;"></iframe>');
        $("#modal-resource").modal();
    });

    // Enviar inmueble
    $(document).on('click', '.btn-enviar-inmueble', function (e) {
        e.preventDefault();
        $("#enviar-inmueble").modal();
    });

    // Popup Resources
    $(document).on('click', '.popup-image', function (e) {
        e.preventDefault();
        image = $(this).attr('href');
        $("#modal-resource-body").html('<img src="' + image + '" alt="" class="img-responsive" />');
        $("#modal-resource").modal();
    });

    // ScrollReveal
    ScrollReveal().reveal('.fichas-inmuebles .item', { delay: 400, distance: '120px', interval: 100, origin: 'bottom', easing: 'ease' });
    ScrollReveal().reveal('.fotos-inmueble .foto', { delay: 200, distance: '40px', interval: 25, origin: 'bottom', easing: 'ease' });
    ScrollReveal().reveal('.fotos-edificio .foto', { delay: 200, distance: '40px', interval: 25, origin: 'bottom', easing: 'ease' });

    // Photoswipe

    // PhotoSwipe-HTML
    buildPswdHtml();

    // Inicializar galerías
    initPhotoSwipeFromDOM('.plano-inmueble');
    initPhotoSwipeFromDOM('.fotos-inmueble');
    initPhotoSwipeFromDOM('.fotos-edificio');

    // Iniciar galería de fotos al hacer clic en la cabecera
    if ($('.fotos-inmueble').length) {
        $('body').on('mouseover', '.inmueble-header', function () {
            $('.inmueble-header').css('cursor', 'pointer');
        });
        $('.inmueble-header').click(function (e) {
            $('.fotos-inmueble .foto:first a').click();
        });
    }
});

// Recargar
var recargar = function (form) {
    form.submit();
};

var buildPswdHtml = function () {
    $("body").append([
        '<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">',
        '  <div class="pswp__bg"></div>',
        '  <div class="pswp__scroll-wrap">',
        '    <div class="pswp__container">',
        '      <div class="pswp__item"></div>',
        '      <div class="pswp__item"></div>',
        '      <div class="pswp__item"></div>',
        '    </div>',
        '    <div class="pswp__ui pswp__ui--hidden">',
        '      <div class="pswp__top-bar">',
        '          <div class="pswp__counter"></div>',
        '          <button class="pswp__button pswp__button--close" title="Cerrar (Esc)"></button>',
        '          <button class="pswp__button pswp__button--share" title="Compartir"></button>',
        '          <button class="pswp__button pswp__button--fs" title="Pantalla completa"></button>',
        '          <button class="pswp__button pswp__button--zoom" title="Zoom"></button>',
        '          <div class="pswp__preloader">',
        '            <div class="pswp__preloader__icn">',
        '              <div class="pswp__preloader__cut">',
        '                <div class="pswp__preloader__donut"></div>',
        '              </div>',
        '            </div>',
        '          </div>',
        '      </div>',
        '      <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">',
        '        <div class="pswp__share-tooltip"></div> ',
        '      </div>',
        '      <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"></button>',
        '      <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"></button>',
        '      <div class="pswp__caption">',
        '        <div class="pswp__caption__center"></div>',
        '      </div>',
        '    </div>',
        '  </div>',
        '</div>'
    ].join(""));
};

//PHOTOSWIPE
var initPhotoSwipeFromDOM = function (gallerySelector) {


    // parse slide data (url, title, size ...) from DOM elements
    // (children of gallerySelector)
    var parseThumbnailElements = function (el) {
        var thumbElements = el.childNodes,
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;

        for (var i = 0; i < numNodes; i++) {

            figureEl = thumbElements[i]; // <figure> element

            // include only element nodes
            if (figureEl.nodeType !== 1) {
                continue;
            }

            linkEl = figureEl.children[0]; // <a> element



            size = linkEl.getAttribute('data-size').split('x');

            // create slide object
            item = {
                src: linkEl.getAttribute('href'),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
            };

            if (figureEl.children.length > 1) {
                // <figcaption> content
                item.title = figureEl.children[1].innerHTML;
            }

            if (linkEl.children.length > 0) {
                // <img> thumbnail element, retrieving thumbnail url
                item.msrc = linkEl.children[0].getAttribute('src');
            }

            item.el = figureEl; // save link to element for getThumbBoundsFn
            items.push(item);
        }

        return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
        return el && (fn(el) ? el : closest(el.parentNode, fn));
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function (e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function (el) {
            return (el.tagName && el.tagName.toUpperCase() === 'DIV');
        });

        if (!clickedListItem) {
            return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
            childNodes = clickedListItem.parentNode.childNodes,
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;

        for (var i = 0; i < numChildNodes; i++) {
            if (childNodes[i].nodeType !== 1) {
                continue;
            }

            if (childNodes[i] === clickedListItem) {
                index = nodeIndex;
                break;
            }
            nodeIndex++;
        }


        if (index >= 0) {
            // open PhotoSwipe if valid index found
            openPhotoSwipe(index, clickedGallery);
        }
        return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    var photoswipeParseHash = function () {
        var hash = window.location.hash.substring(1),
            params = {};

        if (hash.length < 5) {
            return params;
        }

        var vars = hash.split('&');
        for (var i = 0; i < vars.length; i++) {
            if (!vars[i]) {
                continue;
            }
            var pair = vars[i].split('=');
            if (pair.length < 2) {
                continue;
            }
            params[pair[0]] = pair[1];
        }

        if (params.gid) {
            params.gid = parseInt(params.gid, 10);
        }

        return params;
    };

    var openPhotoSwipe = function (index, galleryElement, disableAnimation, fromURL) {

        var pswpElement = document.querySelectorAll('.pswp')[0],
            gallery,
            options,
            items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {

            shareEl: false,
            // define gallery index (for URL)
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),

            getThumbBoundsFn: function (index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect();

                return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
            }

        };

        // PhotoSwipe opened from URL
        if (fromURL) {
            if (options.galleryPIDs) {
                // parse real index when custom PIDs are used
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for (var j = 0; j < items.length; j++) {
                    if (items[j].pid == index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        }

        // exit if index not found
        if (isNaN(options.index)) {
            return;
        }

        if (disableAnimation) {
            options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    };

    // loop through all gallery elements and bind events
    var galleryElements = document.querySelectorAll(gallerySelector);

    for (var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i + 1);
        galleryElements[i].onclick = onThumbnailsClick;
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    var hashData = photoswipeParseHash();
    if (hashData.pid && hashData.gid) {
        openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
    }
};